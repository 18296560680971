@font-face {
  font-family: "CustomFont2";
  src: url("./Assets/SigmarOne-Regular.ttf") format('truetype');
  text-align: center;
}

.App {
  overflow-x: hidden;
  // text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  //-webkit-text-stroke: 0.25px black;
  // font-family: 'CustomFont';
  font-family: 'CustomFont2', sans-serif; //!important;
  color: white;

  min-height: 100vh;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.flexbox-root {
  display: flex;
}

.gallery-image {
  width: 25%;
  flex: 1;
  min-width: calc(25% - 10px);
  /* Adjust the width and margin as needed */
  margin: 5px;
  text-align: center;
  background-color: #f0f0f0;
  padding: 10px;
}

.gallery {
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
}

.primary-content {
  width: 100%;

}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    //animation: App-logo-spin infinite 20s linear;
  }
}

.background::before {
  content: "";
  position: fixed;
  top: -200px;
  right: -200px;
  bottom: -200px;
  left: -200px;
  background-color: rgba(0, 0, 0, 0.1);
  /* Adjust the opacity value (0.5) to make it darker or lighter */
}

.game-logo {
  position: relative;

  width: 1000px;
  border-bottom: 1px solid white;

}

.ehg-face {
  left: 7px;
  top: 7px;
  position: fixed;
  max-width: 42px;
  max-height: 42px;
}

.social {
  margin-left: 10px;
  width: 100%;
}

.tech-icons {
  justify-content: center;
}

.tech-icon {
  margin: 2%;
}

a {
  color: white;
}

.socials-root {
  padding-top: 10px;
  z-index: 1000;
  position: fixed;
  top: 0;
  width: 100%;
  // background-color: white;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  /* Horizontally center the content */
  align-items: center;
  /* Vertically center the content */

}

.flexbox {
  display: flex;
}

.back-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: black;

  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.back-btn-container {
  margin: 1%;
}

.flexbox-content {
  //border: solid red;
  display: flex;
  flex-direction: row;
  //  justify-content: left !important;
}

@media (max-width: 1000px) {
  .flexbox-content {
    display: block;
  }
  .content-wrapper {
    display: block !important;
  }
  .pre-block {
    width: 90% !important;
  }
  .block-wrapper {
    display: block !important;
  }
}

.code-wrapper {
  width: 100%;
  max-width: 100% !important;
  position: relative;


}

.center-div {
  //justify-content: center;
}

.content-wrapper {
  width: 100%;
  margin: 0%;

  // max-width: 100% !important;
  // border: solid red;
  display: flex;
  // padding: 20px;

  img {
    width: 25%;
    max-height: 300px;

    //  max-width: 10%;
    //    max-height: 1%;//40vh;
  }

}

.pre-tag-full {
  //font-family: 'CustomFont2', sans-serif; // !important;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  // padding: 15px;
  //  min-width: 50%;
  //width: 90%;
  width: 60%;
  left: 15%;
  padding: 5%;

  white-space: pre-line;
  //max-width: 60%;
  position: relative;

  text-align: left;

  //-webkit-text-stroke: 1px black; //!important;
  video {
    //justify-content: center;
    position: relative;

  }

  .pre-block {
    //max-width: 100%;
    //width: 100%;
    width: 48%;
    padding: 1%;
    //   border: solid blue;
    position: relative;
    display: flex;
    justify-content: center;
    /* Center content horizontally */
    align-items: center;
    /* Center content vertically */
    /* Vertical centering */
    // padding: 10px;
  }

  .block-wrapper {
    position: relative;


    justify-content: flex-start;
    /* Align items to the left horizontally */
    align-items: center;
    /* Optional: vertically center items */
    flex-wrap: wrap;
    /* Optional: wrap items if needed */

    //    border: solid red;
  }

  ul {}
}

@media (max-width: 1600px) {
  .pre-tag-full {







    // border: solid blue;
    left: 0;
    width: 100%;
    // border: solid red;
  }

  .pre-tag {
    img {
      margin-bottom: 2%;

    }
  }

  .pre-tag-img {
    display: block;
    // width: 100% !important;
    //border: solid red;
    //  flex-direction: row !important;
    //border: solid red;

    img {
      width: 50%;
    }
  }
}

.current-link {
  color: purple;
}

@media (max-width: 1200px) {


  .content-chunk-text {
    // border: solid red;

    h3 {
      //    font-size: 20px !important;
    }
  }

  .monster-img {
    display: none;
  }

  .pre-tag {
    left: 0% !important;

    //width: 80% !important;
    //      border: solid red;
    //    margin-left: 0 !important;
    width: 90% !important;
  }

  .small-padding-tag {
    padding: 0.5% !important; //!important;
    width: 99% !important; //!important;
  }

  .content-wrapper {}









  .monster-img {}


}

.pre-tag-img {
  display: flex;
  flex-direction: column;
  //border: solid red;

  img {
    width: 100%;

  }
}

.nav-btn:hover {
  color: red;
  cursor: pointer;
}

.nav-btn {
  margin: 5px;
}

.social-wrapper {
  display: flex;
  /* Use flexbox layout */
  align-items: center;
  /* Align items vertically in the center */
  justify-content: space-between;
  /* Optional: adjust spacing between items */

}

.award {
  width: 500px !important;
}

.pre-tag {

  margin-bottom: 10px;
  //font-family: 'CustomFont2', sans-serif; // !important;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  //padding: 15px;

  //  min-width: 50%;
  //width: 50%;
  width: 60%;
  left: 15%;
  padding: 5%;
  //border: solid blue;
  white-space: pre-line;
  //max-width: 60%;
  position: relative;
  font-size: calc(1vw + 1vh + 0.5vmin);
  ;
  text-align: left;
  //-webkit-text-stroke: 1px black; //!important;


  ul {}

  div {
    max-width: 100%;
    //  padding:10px;
  }
}

.small-padding-tag {
  padding: 0.5%; //!important;
  width: 69%; //!important;
}

html {
  // font-size: calc(1vw + 1vh + 0.5vmin); /* Scales based on viewport size */
}

.content-chunk {
  max-width: 100%;
  width: 100%;

  justify-content: center;
  text-align: center;

  //font-size: calc(1vw + 1vh + 0.5vmin); //32px;


  h3 {
    padding: 10px;
    font-size: 42px;
  }

  hr {
    display: none;
    width: 35%;
  }
}

.pre-block {
  video {
    margin: 20px;
  }
}

.list-title {
  color: red;
}

.btn {
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.1);
}

.btn:hover {
  color: red;
  background-color: white;
  cursor: pointer;
}

.list-paragraph {
  font-size: 24px;

}

.code-architecture {
  max-width: 100% !important;
  /* Image won't exceed the container's width */
  max-height: 100% !important;
  /* Image won't exceed the container's height */
  width: auto !important;
  /* Let the image width adjust to its original aspect ratio */
  height: auto !important;
  /* Let the image height adjust to its original aspect ratio */


}

.download-btn:hover {
  background-color: #0056b3;
  /* Change to a darker shade for hover effect */
}

.download-btn.large {
  padding: 15px 30px;
  font-size: 18px;
}

.download-btn.red {
  background-color: #ff0000;
  /* Change to a red color */
}

.download-btn {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: inline-block;
  padding: 10px 20px;
  background-color: #0074d9;
  /* Change to your desired background color */
  color: #fff;
  /* Text color */
  text-decoration: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  transition: background-color 0.3s;
}

.reset-font {
  font-family: sans-serif;

}

.list-paragraph2 {
  max-width: 100%;

  div {
    // border: solid red;
    width: 100%;
  }

  font-family: sans-serif;
  font-size: 16px;
  color: white;
  //-webkit-text-stroke: 0.25px black;

  // -webkit-text-stroke: none !important;
  li {
    a {
      color: blue;
    }
  }
}

video {
  max-width: 100% !important;
}

.link {
  color: blue;
  //color:purple
}

.list-paragraph3 {
  font-size: 12px;

}

.block-wrapper {
  display: flex;
}

.content-chunk-text {
  //font-weight: bold;
  //background-color: white;
  justify-content: center;
  justify-items: center;
  //width: 25%;
  //left: 35%;
  position: relative;
  color: red;
  -webkit-text-stroke: 0.25px black;
}

.ehg-highlight-font {
  color: red !important;
  -webkit-text-stroke: 0.25px black;

}

.blur-hard {

  filter: blur(15px);
  transition: 2.2s ease-in-out;
}


.blur {
  filter: blur(3px);
  transition: 2.2s ease-in-out;
}

.no-blur {
  filter: blur(0px);
  transition: 2.2s ease-in-out;
}

.background {
  // transition: background-position-y 0.2s ease;
  z-index: -1;
  // background-image: url("./Assets/scr2_upscaled.png");
  background-image: url("./Assets/punched.png");

  background-size: cover;
  /* Scale the background image to cover the entire div */
  background-position: center;
  /* Center the background image */
  background-repeat: no-repeat;
  /* Prevent the background image from repeating */
  //filter: blur(3px);
  position: fixed;
  top: 0;

  width: 100%;
  height: 100vh;
}

.App-header {
  // background-color: #282c34;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.reset-left {
  left: 0%;
}